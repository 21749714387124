import React from 'react';
import './Contact.css';

const Contact: React.FC = () => {
  return (
    <div className="contact-us-container">
      <h1>Contact Us</h1>
      <div className="general-contact">
        <h2>General Contact Information</h2>
        <p><strong>Address:</strong> 154 Tarean Rd, Karuah NSW 2324</p>
        <p><strong>Phone:</strong> 0494 033 043</p>
        <p><strong>Email:</strong> <a href="mailto:karuahgolf@outlook.com">karuahgolf@outlook.com</a></p>
        <div className="facebook-link">
            <p><strong>Facebook Group:</strong></p>
            <a href="https://www.facebook.com/groups/1228035690639230/" target="_blank" rel="noopener noreferrer">
                <p>Karuah Golf Club Facebook Page</p>
            </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;

//       <div className="people-contact">
//         <h2>Other Contacts</h2>
//         <div className="contact-card">
//           <h3>Gartung - Caretaker</h3>
//           {/* <p>Email: <a href="mailto:bobbrown@example.com">bobbrown@example.com</a></p> */}
//           <p>Phone: 0494 033 043</p>
//           <p>For enquires for extra carts or grounds related issues.</p>
//         </div>
//         <div className="contact-card">
//           <h3>Trev Cumins</h3>
//           {/* <p>Email: <a href="mailto:janesmith@example.com">janesmith@example.com</a></p> */}
//           <p>Phone:  0407 971 037</p>
//           <p>For enquires about the clubhouse and functions.</p>
//         </div>
//       </div>