import React from 'react';
import './InfoSection.css';

interface InfoSectionProps {
  logo: string;
  title: string;
  description: string;
  image: string;
}

const InfoSection: React.FC<InfoSectionProps> = ({ logo, title, description, image }) => {
  return (
<div className="info-section">
      <div className="info-header">
        <img src={logo} alt="Logo" className="info-logo" />
        <h1 className="info-title">{title}</h1>
        <p className="info-description">{description}</p>
      </div>
      <div className="info-image">
        <img src={image} alt="Main" className="info-main-image" />
      </div>
    </div>
  );
};

export default InfoSection;
