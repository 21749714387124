import React from 'react';
import './Footer.css';

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section address">
                    <h4>Address</h4>
                    <p>154 Tarean Rd, Karuah NSW 2324</p>
                </div>
                <div className="footer-section contact">
                    <h4>Contact Us</h4>
                    <p>Email: karuahgolf@outlook.com</p>
                    <p>Phone: 0494 033 043</p>
                </div>
                <div className="footer-section social">
                    <h4>Join our Facebook Group</h4>
                    <a href="https://www.facebook.com/groups/1228035690639230/" target="_blank" rel="noopener noreferrer">
                        <img className="facebookImg" src="images/icons/facebook.png" alt="Facebook" />
                    </a>
                    <p></p>
                </div>
            </div>
            <div className='icons'>
                <p title="event icons">It is important that we thank the amazing icon creators.</p><p>
                                        Event icons created by iconixar - Flaticon.
                                        Golf icons created by Freepik - Flaticon. Golf cart icons created by Smashicons - Flaticon.
                                        Golf cart icons created by Smashicons - Flaticon.
                                        Happy face icons created by th studio - Flaticon.
                                        Committee icons created by Hilmy Abiyyu A. - Flaticon.
                                        Facebook icons created by fauzin idea - Flaticon.
                                        Golf icons created by Good Ware - Flaticon.</p>
            </div>
        </footer>
    );
}

export default Footer;
