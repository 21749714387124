import { Timestamp } from "firebase/firestore"; 
import { EventType } from "../components/types/event_types";

export const getEvents = ()=> {
    return events();
}

const events = () => {
    return [
            {
                "date": Timestamp.fromDate(new Date("November 01, 2024 12:00:00")),
                "repeating": true,
                "repeating_rate": "weekly",
                "consider_time": true,
                "title": "Friday Floggers stroke event",
                "description": "Tee off from midday, 9 holes, club open from 3pm"
            } ,
            {
                "repeating": false,
                "consider_time": false,
                "description": "Sponsored by Russ's Fresh Bake",
                "title": "4BBB Stableford",
                "date": Timestamp.fromDate(new Date("October 19, 2024"))    
            } ,
            {
                // "repeating_rate": "weekly",
                "repeating": false,
                "title": "Vets Championship",
                "consider_time": true,
                "description": "Vets last round of Stroke Championship",
                "date2_purpose": "start",
                "date": Timestamp.fromDate(new Date("October 29, 2024 07:00:00")),
                "date2": Timestamp.fromDate(new Date("October 29, 2024 07:30:00"))
            } ,
            {
                "title": "Irish 4BBB 6 x 6 x 6",
                "description": "Sponsored by Russ's Fresh Bake",
                "date": Timestamp.fromDate(new Date("September 21, 2024")),
                "repeating": false,
                "consider_time": false
            } ,
            {
                "consider_time": true,
                "date": Timestamp.fromDate(new Date("December 14, 2024 8:00:00")),
                "repeating": false,
                "description": "Sponsored by Karuah RSL. 8am shotgun start. Short Course Presentations",
                "title": "Last Person Standing"
            } ,
            {
                "consider_time": false,
                "description": "Sponsored by Raymond Terrace Bowling Club",
                "repeating": false,
                "date": Timestamp.fromDate(new Date("October 26, 2024")),
                "title": "Stableford"
            } ,
            {
                "repeating": false,
                "date": Timestamp.fromDate(new Date("August 24, 2024")),
                "consider_time": false,
                "title": "Stableford",
                "description": "Sponsored by Raymond Terrace Bowling Club"
            } ,
            {
                "date": Timestamp.fromDate(new Date("January 18, 2025")),
                "title": "2 Person Stableford Aggregate",
                "description": "Sponsored by Russ's Fresh Bake",
                "consider_time": false,
                "repeating": false
            } ,
            {
                "date": Timestamp.fromDate(new Date("August 14, 2024")),
                "title": "4BBB Stableford",
                "repeating": false,
                "consider_time": false,
                "description": ""
            } ,
            {
                "title": "Stableford",
                "consider_time": false,
                "repeating": false,
                "description": "Sponsored by Raymond Terrace Bowling Club",
                "date": Timestamp.fromDate(new Date("December 28, 2024"))
            } ,
            {
                "title": "Stableford",
                "description": "",
                "consider_time": false,
                "date": Timestamp.fromDate(new Date("August 10, 2024")),
                "repeating": false
            } ,
            {
                "consider_time": false,
                "repeating": false,
                "title": "Stableford",
                "date": Timestamp.fromDate(new Date("September 24, 2024")),
                "description": "Sponsored by Raymond Terrace Bowling Club"
            } ,
            {
                "consider_time": false,
                "repeating": false,
                "title": "Stableford Championship Rounds",
                "description": "Vets Stableford Championship Rounds",
                "date": Timestamp.fromDate(new Date("November 19, 2024"))
            } ,
            {
                "consider_time": false,
                "repeating": false,
                "title": "Stableford Championship Rounds",
                "description": "Vets Stableford Championship Rounds",
                "date": Timestamp.fromDate(new Date("November 26, 2024"))
            } ,
            {
                "repeating_rate": "second_saturday",
                "consider_time": false,
                "repeating": true,
                "title": "Stableford",
                "description": "",
                "date": Timestamp.fromDate(new Date("September 14, 2024"))
            } ,
            {
                "title": "Stroke Monthly Mug",
                "date": Timestamp.fromDate(new Date("August 3, 2024")),
                "repeating": true,
                "repeating_rate": "first_saturday",
                "description": " Monthly event stroke mug",
                "time": false
            } ,
            {
                "description": "8am start",
                "repeating": false,
                "consider_time": true,
                "date": Timestamp.fromDate(new Date("December 21, 2024, 08:00:00")),
                "title": "Christmas Ambrose"
            } ,
            {
                "description": "Sponsors day ! 8am shotgun start",
                "title": "4 Person Ambrose",
                "date": Timestamp.fromDate(new Date("September 24, 2024 08:00:00")),
                "consider_time": true,
                "repeating": false
            } 
        ];
}

export const castTime = (date: Date) => {    
    let formatter = new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });
    
    let formattedTime = formatter.format(date);
    return formattedTime;
}

export const filterEvents = (events: EventType[]): EventType[]  => {
    const now = new Date();
    const filteredEvents = events.filter(event => event.date.toDate() >= now);
    return filteredEvents;
}

export const generateRepeatingEvents = (event: EventType): EventType[] => {
    const { repeating, repeating_rate } = event;
    const generatedEvents: EventType[] = [];
    
    if (!repeating || !repeating_rate) {
        return [event]; // Return the original event if it's not repeating
    }

    let currentEventDate = event.date.toDate();
    const sevenMonthsLater = new Date();
    sevenMonthsLater.setMonth(sevenMonthsLater.getMonth() + 7);

    while (currentEventDate <= sevenMonthsLater) {
        generatedEvents.push({
            ...event,
            date: Timestamp.fromDate(new Date(currentEventDate))
        });

        // Calculate the next date based on repeating_rate
        switch (repeating_rate) {
            case "weekly":
                currentEventDate.setDate(currentEventDate.getDate() + 7);
                break;
            case "monthly":
                currentEventDate.setMonth(currentEventDate.getMonth() + 1);
                break;
            case "yearly":
                currentEventDate.setFullYear(currentEventDate.getFullYear() + 1);
                break;
            case "first_saturday":
                // Move to the first day of the next month
                currentEventDate.setMonth(currentEventDate.getMonth() + 1);
                currentEventDate.setDate(1);

                // Find the first Saturday of the month
                while (currentEventDate.getDay() !== 6) { // 6 is Saturday
                    currentEventDate.setDate(currentEventDate.getDate() + 1);
                }
                break;
            case "last_friday":
                currentEventDate.setMonth(currentEventDate.getMonth() + 1);
                currentEventDate.setDate(0); // Set to last day of the previous month
                while (currentEventDate.getDay() !== 5) { // 5 is Friday
                    currentEventDate.setDate(currentEventDate.getDate() - 1);
                }
                break;
            case "second_saturday":
                // Move to the first day of the next month
                currentEventDate.setMonth(currentEventDate.getMonth() + 1);
                currentEventDate.setDate(1);
                // Find the first Saturday of the month
                while (currentEventDate.getDay() !== 6) { // 6 is Saturday
                    currentEventDate.setDate(currentEventDate.getDate() + 1);
                }
                // Move to the second Saturday of the month
                currentEventDate.setDate(currentEventDate.getDate() + 7);
                break; 
            case "last_saturday":
                   // Move to the last day of the current month
                    currentEventDate.setMonth(currentEventDate.getMonth() + 1);
                    currentEventDate.setDate(0); // Set to last day of the previous month, effectively last day of the current month

                    // Find the last Saturday of the month
                    while (currentEventDate.getDay() !== 6) { // 6 is Saturday
                        currentEventDate.setDate(currentEventDate.getDate() - 1);
                    }
                    break; 
            default:
                currentEventDate.setFullYear(currentEventDate.getFullYear() + 1);
                break;
        }
    }

    return generatedEvents;
};