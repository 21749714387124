import './SponsorCarousel.css'; // Import the CSS file for styling

interface Sponsor {
  id: number;
  src: string;
  alt: string;
  className?: string;
}

const shuffle = (array: Sponsor[]) => { 
  return array.map((a) => ({ sort: Math.random(), value: a }))
      .sort((a, b) => a.sort - b.sort)
      .map((a) => a.value); 
}; 

const sponsors: Sponsor[] = [
  { id: 1, src: '/images/sponsors/toro.png', alt: 'TORO Australia' },
  { id: 2, src: '/images/sponsors/freedom_homes.png', alt: 'Freedom Homes', className: 'large' },
  { id: 3, src: '/images/sponsors/newcastle_golf_club.png', alt: 'Newcastle Golf Club', className: 'large' },
  { id: 4, src: '/images/sponsors/hunter_steel.png', alt: 'Hunter Steel' },
  { id: 5, src: '/images/sponsors/mutual_bank.png', alt: 'Mutual Bank', className: 'large' },
  { id: 6, src: '/images/sponsors/big_4.jpeg', alt: 'Big 4 Holiday Parks', className: 'small' },
  { id: 7, src: '/images/sponsors/rsl.jpeg', alt: 'Karuah RSL', className: 'small' },
  { id: 8, src: '/images/sponsors/jets.jpeg', alt: 'Raymond Terrace Bowling Club', className: 'large' },
  { id: 9, src: '/images/sponsors/quarries.png', alt: 'Hunter Quarries' },
  { id: 10, src: '/images/sponsors/waters_estate.jpg', alt: 'Waters Estate', className: 'large'},
  { id: 11, src: '/images/sponsors/russs.png', alt: "Russ's Fresh Bake", className: 'large'},
  { id: 12, src: '/images/sponsors/plumbing.png', alt: "Bridgeport Plumbing Services", className: 'large'},
  { id: 13, src: '/images/sponsors/trade.png', alt: "Entire Trades", className: 'large'},
  { id: 14, src: '/images/sponsors/meat.png', alt: "Karuah Quality Meats", className: 'large'},
  { id: 15, src: '/images/sponsors/roofing.png', alt: "Calo-Bond Roofing", className: 'large'},
  { id: 15, src: '/images/sponsors/techmobile.png', alt: "Tech Mobile", className: 'large'},
  // Add more sponsors here
];

interface SponsorCarouselProps {
  title?: string;
}

export const SponsorCarousel: React.FC<SponsorCarouselProps>  = ({ title }) => {
  const shuffled = shuffle(sponsors);
  const allSponsors = Array(50).fill(shuffled).flat();

  return (
    <div>
      {title && <p className='sponsor-title'>{title}</p>}
      <div className="sponsor-carousel">
        <div className="sponsor-carousel-inner">
          {allSponsors.map((sponsor) => (
            <div key={sponsor.id} className={`sponsor-item ${sponsor.className || ''}`}>
              <img src={sponsor.src} alt={sponsor.alt} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};