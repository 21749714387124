import React from 'react';
import './Membership.css'; // Custom CSS for styling
import './Sponsorship.css'; // Custom CSS for styling
import MembershipPricing from '../elements/membership/MembershipPricing';
import MembershipForm from '../elements/membership/MembershipForm';
import MembershipBenefits from '../elements/membership/MembershipBenefits';
import { SponsorCarousel } from '../elements/SponsorCarousel';

const Sponsorship: React.FC = () => {
    const benefits = [
        "Starting the New Year Sponsorship New and Current sponsors",
        "Need Hole sponsors First in will get to pick there hole number",
        "Costs will cover a Sign with Logo of sponsors details etc",
        "600 mm High x 1000mm Long signage",
        "weekly sponsor for Saturday Comp Days",
        "Costs cover signage, sponsor days"
    ];

    const price = 500;
    const priceSecondary = 250;

    const instructions = [
        'Download the sponsorship pack above',
        'Please email or phone for any enquires',
        'Email karuahgolf@outlook.com',
        'Phone 0494 033 043',
    ];
    const logo = 'images/icons/golf-field.png';

    return ( 
            <div>
                <div className="membership">
                <div className="membership-header">
                    <img src={logo} alt="Logo" className="info-logo" />
                    <h1>About our Sponsorshop</h1>
                    {/* <FaArrowDown /> */}
                </div>
                <MembershipBenefits title={"Sponsorship Benefits"} benefits={benefits} />
                <MembershipPricing title={"Sponsorship Pricing"} price={price} priceSecondary={priceSecondary} />
                <MembershipForm
                    buttonName="Download Information"
                    title="Sponsorship Information"
                    formLink="/files/KaruahGolfSponsorship.docx"
                    instructions={instructions}
                    // postSubmissionInstructions={postSubmissionInstructions}
                />
            </div>
            <div className='membership-header sponsorship-footer'>
                <h1>Thanks to our amazing sponsors</h1>
                <div className='sponsorship'>
                    {/* <FaArrowDown /> */}
                </div>
                <SponsorCarousel background-color={"none"}/>
            </div>
         </div>

            
       
    );
};

export default Sponsorship;
