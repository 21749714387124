import React from 'react';
import './Membership.css'; // Custom CSS for styling
import MembershipPricing from '../elements/membership/MembershipPricing';
import MembershipForm from '../elements/membership/MembershipForm';
import MembershipBenefits from '../elements/membership/MembershipBenefits';

const Membership: React.FC = () => {
    const benefits = [
        'Access to exclusive events',
        'Discounted rates on all services',
        'Priority support',
        'Member-only content',
    ];

    const price = 330;

    const instructions = [
        'Download the form using the link above.',
        'Fill out the form with your details.',
        'Submit the completed form to karuahgolf@outlook.com',
    ];

    const postSubmissionInstructions = 'You will receive a confirmation email within 3-5 business days.';

    const logo = 'images/icons/golf.png';

    return ( 
            <div className="membership">
                <div className="membership-header">
                    <img src={logo} alt="Logo" className="info-logo" />
                    <h1>About our Membership</h1>
                    {/* <FaArrowDown /> */}
                </div>
                <MembershipBenefits title={"Membership benefits"} benefits={benefits} />
                <MembershipPricing title={"Membership Pricing"} price={price} />
                <MembershipForm
                    buttonName="Download new member nomination"
                    secondaryButtonName='Member update register'
                    title="Membership Form"
                    formLink="/files/KaruahGolfMembershipForm.docx"
                    secondaryFormLink="/files/KaruahGolfMembershipFormUpdate.docx"
                    instructions={instructions}
                    postSubmissionInstructions={postSubmissionInstructions}
                />
            </div>
       
    );
};

export default Membership;
