import React from 'react';
import './MembershipPricing.css'; // Custom CSS for styling

interface MembershipPricingProps {
    price: number;
    priceSecondary?: number;
    title: string;
}

const MembershipPricing: React.FC<MembershipPricingProps> = ({ price, priceSecondary, title }) => {
    return (
        <div className="membership-pricing">
            <h2>{title}</h2>
            <p>${price.toFixed(2)} per annum</p>
            { priceSecondary && <p>${priceSecondary.toFixed(2)} per annum for following years.</p>}
        </div>
    );
};

export default MembershipPricing;
