import "./Form.css";

type FormProps = {
    src: string;
    loadingText: string;
}

export const Form = ({ src, loadingText }: FormProps) => {
    return (
        <div className="reservation_form">
            <iframe src={src}>{loadingText}</iframe>
        </div>
    )
}