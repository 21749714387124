import React from 'react';
import {SponsorCarousel} from '../elements/SponsorCarousel';
import { IoGolf, IoCalendar, IoPeople } from 'react-icons/io5';
import InfoSection from '../elements/InfoSection';
import TiledSection from '../elements/TiledSection';
import './Home.css';
const Home = () => {

    const logo = 'images/icons/golf.png';
    const title = 'Welcome to Karuah Golf Club';
    const description = "Located in the beautiful northern shores of Port Stephens, a short 20 minute drive from Hexam Bridge. Karuah Golf Club is a tree-lined 9 hole golf course and is a low cost friendly, voluntary run golf course.";
    const image = 'images/course_images/Karuah-GC.png';

    const tiles = [
        {
            imageSrc: "/images/icons/golf-cart.png", // Replace with the actual path to your image
            title: "Facilities and Pricing",
            description: "Enjoy our wonderful facilities and services.",
            link: "/facilities",
        },
        {
            imageSrc: "/images/icons/event.png", // Replace with the actual path to your image
            title: "Events",
            description: "Check out our upcoming events and activities.",
            link: "/events",
        },
        {
            imageSrc: "/images/icons/team.png", // Replace with the actual path to your image
            title: "Members",
            description: "Join our community, become a member today.",
            link: "/membership",
        },
    ];
    return (
        <div className="homepage">
            <header className="homepage-header">
                <InfoSection 
                    logo={logo} 
                    title={title} 
                    description={description} 
                    image={image}
                />
            </header>
            <main className="homepage-content">
                <section className="homepage-tiles">
                    <TiledSection tiles={tiles} />
                </section>
                <div className="homepage-sponsors">
                    <SponsorCarousel />
                </div>
            </main>
        </div>
    );
};

export default Home;