import { useLocation } from "react-router-dom";
import { Form } from "../elements/Form";

const MakeReservation = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const name = query.get('name') || "";
    const year = query.get('year') || "";
    const month = query.get('month') || "";
    const day = query.get('day') || "";

    // Corrected the URL by removing the second question mark
    const baseUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSdO49CtCk4vxc9Z0OESDCsn8s9La8CY5hzsl6Xr7Asy8UgnjA/viewform?embedded=true';

    return (
        <body>
            <Form 
                src={`${baseUrl}&usp=pp_url&entry.330058456_year=${encodeURIComponent(year)}&entry.330058456_month=${encodeURIComponent(month)}&entry.330058456_day=${encodeURIComponent(day)}&entry.1385327982=${encodeURIComponent(name)}`} 
                loadingText="Loading..."
            />
        </body>
    )
}

export default MakeReservation;
