import React from 'react';
import './MembershipForm.css'; // Custom CSS for styling

interface MembershipFormProps {
    formLink: string;
    secondaryFormLink?: string;
    instructions: string[];
    postSubmissionInstructions?: string;
    title: string;
    buttonName: string;
    secondaryButtonName?: string;
}

const MembershipForm: React.FC<MembershipFormProps> = ({
    formLink,
    secondaryFormLink,
    instructions,
    postSubmissionInstructions,
    title,
    buttonName,
    secondaryButtonName,
}) => {
    return (
        <div className="membership-form-section">
            <h2>{title}</h2>
            <a href={formLink} className="download-link" download>
                {buttonName}
            </a>
            {secondaryFormLink && 
            <a href={formLink} className="download-link secondary" download>
                {secondaryButtonName}
            </a>}
            <h3>Instructions</h3>
            <ol>
                {instructions.map((instruction, index) => (
                    <li key={index}>{instruction}</li>
                ))}
            </ol>
            { postSubmissionInstructions && <p>{postSubmissionInstructions}</p> }
         </div>
    );
};

export default MembershipForm;
