import React from 'react';
import { FaCheckCircle } from 'react-icons/fa'; // Font Awesome for tick icons
import './MembershipBenefits.css'; // Custom CSS for styling

interface MembershipBenefitsProps {
    benefits: string[];
    title: string;
}

const MembershipBenefits: React.FC<MembershipBenefitsProps> = ({ benefits, title }) => {
    return (
        <div className="membership-benefits">
            <h2>{title}</h2>
            <ul>
                {benefits.map((benefit, index) => (
                    <li key={index}>
                        <FaCheckCircle />
                        {benefit}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default MembershipBenefits;
