import React from 'react';
import './TiledSection.css';

interface TileProps {
    imageSrc: string;
    title: string;
    description: string;
    link: string;
}

interface TiledSectionProps {
    tiles: TileProps[];
}

const TiledSection: React.FC<TiledSectionProps> = ({ tiles }) => {
    return (
        <section className="tiled-section">
            {tiles.map((tile, index) => (
                <a href={tile.link} className="tile" key={index}>
                    <img src={tile.imageSrc} alt={tile.title} className="tile-icon" />
                    <h3 className="tile-title">{tile.title}</h3>
                    <p className="tile-description">{tile.description}</p>
                </a>
            ))}
        </section>
    );
};

export default TiledSection;
