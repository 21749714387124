import React from 'react';
import './Facilities.css';
import { useNavigate } from 'react-router-dom';

const FacilityTile: React.FC<{ image?: string, title: string, text: string, reverse?: boolean }> = ({ image, title, text, reverse }) => {
    const navigate = useNavigate();
    const handleClick = () => {
        // Navigate to the /make-reservation page with a query parameter
        navigate(`/membership`);
    };

  function getWindowDimensions() {
      const { innerWidth: width, innerHeight: height } = window;
      return {
        width,
        height
      };
    }

  return (
    <div className={`facility-tile ${reverse ? 'reverse' : ''}`}>
      {(image && getWindowDimensions().width > 730) && (<div className="image-container">
        <img src={image} alt={title} />
      </div>)}
      <div className="text-container">
        <h2>{title}</h2>
        {title === "Pricing" && (<p>Non-member pricing:</p>)}
        <p>{text}</p>
        {title === "Pricing" && (<p>Payment for the round can be made on Vendi tap & pay or via cash box</p>)}
        {title === "Pricing" && (<p>Interested in becoming a member?</p>)}
        {(title === "Social Group bookings" || title === "Clubhouse available for functions") && (<p>Contact the club for bookings. Ph. 0494 033 043</p>)}
        <div className="button-container">
            {title === "Pricing" && (
            <button className="become-member-button" onClick={handleClick}>Membership Information</button>
            )}
        </div>
      </div>
    </div>
  );
};

const FacilitiesPage: React.FC = () => {
    const clubSpaceImage = './images/course_images/Karuah-GC.png';
    const cartsImage = './images/course_images/vendi.png';
    const clubImage = './images/course_images/karuah_golf_resized.jpeg';
    const socialImage = './images/course_images/golf-balls.jpeg';

  return (
    <div className="facilities-page">
      <h1>Our Facilities</h1>

      <FacilityTile
        image={clubSpaceImage}
        title="The course"
        text="Karuah Golf Club is a tree-lined 9 hole golf course. It is a casual and friendly, voluntary run golf course."
        reverse={true}
      />

      <FacilityTile
        image={cartsImage}
        title="Carts"
        text="We offer Vendi tap-and-pay carts for $30."
      />

      <FacilityTile
        image={clubImage}
        title="Clubhouse available for functions"
        text="Full kitchen - Bar available - Birthdays, corporate events etc"
        reverse={true}
      />

      <FacilityTile
        image={socialImage}
        title="Social Group bookings"
        text="Carts available - BBQ facilities"
      />

      <FacilityTile
        title="Pricing"
        text="$22 per day - $17 for 9 holes - Junior rates available"
        reverse={true}
      />
    </div>
  );
};

export default FacilitiesPage;
